<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">题库管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">题库列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="题库名称" class="searchboxItem ci-full">
              <span class="itemLabel">题库名称:</span>
              <el-input
                v-model="questionbankName"
                type="text"
                size="small"
                placeholder="请输入题库名称"
                clearable
              />
            </div>
            <span title="题库类别" class="searchboxItem ci-full flexcc">
              <span class="itemLabel">题库类别:</span>
              <el-cascader
                v-model="categoryCode"
                :options="TreeList"
                :props="propsTree"
                clearable
                filterable
                size="small"
              ></el-cascader>
            </span>
            <el-button
              class="bgc-bv"
              style="margin:0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
            <el-button
              class="bgc-bv"
              style="margin:0 10px"
              round
              @click="handlAdd()"
              >新增</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                min-width="100"
              />
              <el-table-column
                label="题库名称"
                align="left"
                prop="questionBankName"
                show-overflow-tooltip
                min-width="140"
              />
              <el-table-column
                label="题库类别"
                align="left"
                show-overflow-tooltip
                min-width="140"
              >
                <template slot-scope="scope">{{
                  scope.row.questionCategory || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="题目数量"
                align="center"
                prop="questionBankNum"
                show-overflow-tooltip
                max-width="140"
              >
                <template slot-scope="scope">
                  {{ scope.row.questionBankNum || 0 }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="110"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="
                      handlEdit(
                        scope.row.questionBankId,
                        scope.row.questionBankName,
                        scope.row.questionCategory
                      )
                    "
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="
                      handlDelete(
                        scope.row.questionBankId,
                        scope.row.needRemind,
                        scope.row.examName
                      )
                    "
                    >删除</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "questionbankList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      questionbankName: "", //题库名称
      categoryCode: "", //题库類別
      questionBankStateList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "10",
          label: "是",
        },
        {
          value: "20",
          label: "否",
        },
      ],
      TreeList: [],
      propsTree: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
    };
  },
  created() {
    this.getTree();
  },
  computed: {},
  methods: {
    /* 获取列表数据 */
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.questionbankName) {
        params.questionBankName = this.questionbankName;
      }
      if (this.categoryCode) {
        params.categoryCode = this.categoryCode;
      }
      if (this.questionBankState) {
        params.questionBankState = this.questionBankState;
      }
      this.doFetch(
        {
          url: "/evaluate/question/bank/page",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    /* 题库类别 */
    getTree() {
      this.$post(
        "/miniapp/category/enable/tree",
        { disabledLevels: "1,2,3" },
        3000,
        true,
        2
      ).then((ret) => {
        this.TreeList = ret.data;
      });
    },
    /* 新增 */
    handlAdd() {
      this.$router.push({
        path: "/web/appraisalInstitution/appraisalQuestionBankAdd",
        query: {
          stu: "add",
        },
      });
      sessionStorage.setItem("questionJson", "");
    },
    /* 编辑 */
    handlEdit(questionBankId, questionBankName, questionCategory) {
      this.$router.push({
        path: "/web/appraisalInstitution/appraisalQuestionBankAdd",
        query: {
          questionBankId,
          stu: "edit",
        },
      });
      let json = JSON.stringify({
        questionBankId,
        questionBankName,
        questionCategory,
      });
      sessionStorage.setItem("questionJson", json);
    },
    /* 删除 */
    handlDelete(questionBankId, needRemind, examName) {
      if (needRemind) {
        this.doDel(
          {
            url: "/evaluate/question/bank/delete",
            msg: "该题库已被【" + examName + "】考试使用，确认删除吗？",
            ps: {
              type: "post",
              data: { questionBankId },
            },
          },
          true,
          2
        );
      } else {
        this.doDel(
          {
            url: "/evaluate/question/bank/delete",
            msg: "你确认删除该题库吗？",
            ps: {
              type: "post",
              data: { questionBankId },
            },
          },
          true,
          2
        );
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        } else {
          this.getData();
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less"></style>
